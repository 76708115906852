.carrelloBg {
  min-height: 60vh;
  width: 60%;
  gap: 20px;
  margin: 20vh auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.productInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 10px;
  padding: 20px;
  box-sizing: border-box;
  gap: 20px;
  position: relative;
  min-width: 60vw;
  max-width: 800px;
}

.productInfo p {
  max-width: 310px;
}
.productInfo h3 {
  max-width: 115px;
}
.removeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
}
.carrelloButtons {
  display: flex;
  margin: 50px;
}
.tornaDashButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #005192;
  color: #005192;
  border-radius: 50px;
  height: 30px;

  padding: 10px 30px;
  cursor: pointer;
  margin: 10px;
}
.procediButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #005192;
  border: 1px solid #005192;
  color: #fff;
  border-radius: 50px;
  height: 30px;

  padding: 10px 30px;
  cursor: pointer;
  margin: 10px;
}
.totalPrice {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.modifyProductsQuantity {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

@media screen and (max-width: 800px) {
  .productInfo {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .productInfo p {
    max-width: 100%;
  }
  .productInfo h3 {
    max-width: 100%;
  }
  .carrelloBg {
    width: 100%;
  }
  .carrelloButtons {
    flex-direction: column;
  }
}
