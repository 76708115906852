.regBg {
  margin-top: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 60vh;
}
.info {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  margin: 50px 0;
  gap: 20px;
}
.infoPersonali {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  flex-direction: column;
}
.infoAccount {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  flex-direction: column;
}
.switchRegLog {
  text-align: center;
  width: 340px;
  margin: 10px 0;
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .info {
    flex-direction: column;
    align-items: center;
  }
  .infoPersonali {
    margin-bottom: 200px;
  }
}
