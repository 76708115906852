.ChisiamoCard {
  position: relative;
  width: 100%;
  max-width: 480px;
  overflow: hidden;
  cursor: pointer;
}

.ChisiamoCard::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0; /* Explicitly set to zero */
  padding: 0; /* Explicitly set to zero */
  background: radial-gradient(
    circle at bottom left,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.6) 40%,
    rgba(0, 0, 0, 0.5) 70%,
    rgba(0, 0, 0, 0.3)
  );
  pointer-events: none;
  z-index: 1;
  width: 100%;
  height: 30vh;
  border-radius: 10px;
}

.ChisiamoCard img {
  width: 100%;
  height: 30vh;
  z-index: 0;
  object-fit: cover;
  border-radius: 10px;
}

.ChisiamoCardInfo {
  position: absolute;
  left: 15px;
  bottom: 15px;
  z-index: 2;
  color: white;
}
.ChisiamoCardInfo h3 {
  margin: 10px 5px !important;
  color: white;
  text-align: left !important;
  font-size: larger;
}
