.home {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  margin-top: 16vh;
}
.homeBg {
  display: flex;
  flex-direction: column;
  min-width: 100%;
}
.imageSliderComponent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 60px;
}
.homeText {
  text-align: center;
  color: #707070;
  margin: 50px 20vw;
}
