.footer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  text-align: center;
  font-size: 14px;
  max-width: 100%;
  height: fit-content;
  margin-top: 220px;
  background-color: #e3e3e3;
  color: #303030;
  position: relative;
  gap: 8%;
}
.logoETIS {
  position: absolute;
  right: 30px;
  bottom: 20px;
  text-decoration: none;
  color: #101010;
}
.footerSections {
  padding: 30px 0 100px 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.footerSection {
  margin: 50px;
  text-align: left;
  max-width: 280px;
  min-width: 280px;
}
.footerSectionSubtitle {
  color: #005192;
  margin-top: 40px;
}

@media screen and (max-width: 1400px) {
  .footerSections {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 800px) {
  .footer {
    margin-top: 0;
  }
  .footerSections {
    grid-template-columns: 1fr;
  }
}
