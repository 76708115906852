h1 {
  margin: 4px;
}
h2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  color: #a0a0a0;
  font-weight: 400;
}
h3 {
  color: #005192;
}
h4 {
  font-size: 16px;
  font-weight: 400;
}

p {
  margin: 4px;
}
a {
  color: #005192;
}
html {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
.space-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
