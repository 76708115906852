.chisiamo {
  max-width: 100vw;
  margin-top: 12vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.chisiamo h1 {
  margin: 50px 0 30px 0;
  text-align: center;
}
.chisiamo h3 {
  margin: 30px;
  text-align: center;
}
.chisiamoText {
  margin: 20px 10px 80px 10px;
  text-align: center;
  justify-self: center;
  align-self: center;
  max-width: 400px;
}

.chisiamoCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90%;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-left: auto;
  margin-right: auto;
}
.noScroll {
  overflow: hidden;
}

.chisiamoSliders {
  gap: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}

.chisiamoSlider {
  cursor: pointer;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;

  max-width: 480px;
  box-sizing: border-box;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  text-align: center;
  gap: 20px;
  margin: 50px 0 -45px 0;
}

.chisiamoSliderText {
  text-align: center;
  margin: 20px 40px;
}
.chisiamoSliderImage {
  margin: 10px;
  display: flex;
  justify-self: center;
  align-self: center;
  min-width: 100px;
  max-height: 70px;
  object-fit: contain;
}
@media screen and (max-width: 800px) {
  .chisiamoSlider {
    margin: 50px 20px -45px 20px;
  }
}
