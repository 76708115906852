.catalogo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12vh;
  flex-direction: column;
}
.catalogo h1 {
  margin: 50px;
  text-align: center;
}
.catalogoCard {
  margin: 50px;
  width: 700px;
  height: 205px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex-direction: row;
  gap: 30px;
  background-color: white;
  border-radius: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 10px 30px 10px 10px;
  box-sizing: border-box;
}

.catalogoCard img {
  width: 135px;
  height: 185px;
  object-fit: cover;
  border-radius: 10px;
}

.catalogoWrapper a {
  text-decoration: none;
  color: white;
  font-weight: 600;
  background-color: #005192;
  padding: 10px 30px;
  border-radius: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.catalogoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  text-align: center;
  flex-direction: row;
  min-height: 100%;
}

@media screen and (max-width: 800px) {
  .catalogoCard {
    width: 90%;
  }
  .catalogoWrapper {
    justify-content: center;
    flex-direction: column;
    gap: 30px;
  }
}
