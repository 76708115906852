.agenti {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12vh;
  flex-direction: column;
}
.agenti h1 {
  margin: 50px;
  text-align: center;
}
.agentiCards {
  margin: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  flex-direction: row;
  gap: 30px;
  background-color: white;
}
.agenteCard {
  border-radius: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
  text-align: left;
  padding: 10px 30px 30px 30px;
  box-sizing: border-box;
  min-width: 300px;
  min-height: 400px;
  width: 20vw;
  height: 20vw;
}
