.modalBg {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.moreInfoModal {
  background-color: white;
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;

  gap: 20px;
  padding: 20px 50px 50px 50px;
  box-sizing: border-box;
  border-radius: 10px;
  overflow-y: scroll;
  position: absolute;
  margin: auto;
  z-index: 1001;
}
.moreInfoModalInfos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  gap: 40px;
}
.moreInfoModalInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 10px;
  gap: 10px;
}
.moreInfoModalInfo img {
  width: 150px;
  height: 50px;
  object-fit: contain;
}

.dropZone {
  border-radius: 12px;
  border: 2px dashed #005192;
  min-height: 80%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modalAddCodiceReferente {
  background-color: white;
  width: 40%;
  height: 26%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 10px;
  position: relative;
  z-index: 1001;
  padding: 20px;
}
.modalAddCodiceReferente .mainButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tornaIndietro {
  position: absolute;
  top: 10px;
  left: 20px;
  cursor: pointer;
}
