.loaderBalls {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 60vh;
  gap: 10px;
}
.loaderBall1 {
  width: 20px;
  height: 20px;
  border-radius: 40px;
  background-color: #4169e1;
  animation: loaderBall 1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
}
.loaderBall2 {
  width: 20px;
  height: 20px;
  border-radius: 40px;
  background-color: #4169e1;
  animation: loaderBall 1s cubic-bezier(0.42, 0, 0.58, 1) infinite 0.3s;
}
.loaderBall3 {
  width: 20px;
  height: 20px;
  border-radius: 40px;
  background-color: #4169e1;
  animation: loaderBall 1s cubic-bezier(0.42, 0, 0.58, 1) infinite 0.6s;
}

@keyframes loaderBall {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
