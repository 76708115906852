.admin {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  margin-top: 20vh;
}
.adminBg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: 0 auto;
  text-align: center;
}

.richiestaCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  margin: 20px;
  background-color: white;
  border-radius: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  gap: 30px;
  text-align: left;
}
.richiestaCardTitle p:first-of-type {
  font-size: 20px;
  font-weight: 500;
  color: #005192;
}
.richieste {
  margin-top: 50px;
}
.richiesteButtons {
  display: flex;
}
.accettaRichiestaButton,
.rifiutaRichiestaButton {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 15px;
  margin: 5px;
  cursor: pointer;
  width: max-content;
  text-align: center;
  font-size: 14px;
  color: #000000;
}

.accettaRichiestaButton:hover {
  background-color: rgb(114, 221, 114);
}

.rifiutaRichiestaButton:hover {
  background-color: rgb(221, 114, 114);
}

@media screen and (max-width: 868px) {
  .adminBg {
    width: 90%;
  }
  .richiestaCard {
    flex-direction: column;
    align-items: center;
  }
  .richiestaCardTitle {
    text-align: center;
  }
  .richiesteButtons {
    flex-direction: column;
    align-items: center;
  }
}
