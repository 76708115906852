.sliderMainImages {
  display: flex;
  justify-content: center;
  align-items: center;

  height: fit-content;
  background-color: #fff;
  max-width: 100vw;
}

.sliderMainImage {
  width: fit-content;
  min-height: 60vh;
  max-height: 60vh;
  max-width: 100%;
  object-fit: contain;
  cursor: pointer;
}
.sliderMainImageLogo {
  position: absolute;
  top: 20px;
  left: 40px;
  height: 100px;
  width: 100px;
  object-fit: contain;
}
/* .sliderMainImageLogo {
  position: absolute;
  top: 20px;
  left: 40px;
  height: 100px;
  width: 100px;
  object-fit: contain;
  background-color: #fff;
  border-radius: 50%;
  padding: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
} */

.arrowLeft {
  height: 20px;
  width: 20px;
  padding: 20px;
  position: absolute;
  left: 20px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50px;

  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.arrowRight {
  height: 20px;
  width: 20px;
  padding: 20px;
  position: absolute;
  right: 20px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50px;

  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.arrowLeft:hover,
.arrowRight:hover {
  border-radius: 60px;
  transform: translateY(-2px);
}
.currentIndicatorMain {
  background-color: #00000072;
  border-radius: 30px;
  border: 1px solid #b3b3b3;
  height: 30px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.7rem;
  cursor: pointer;
  position: absolute;
  right: 2rem;
  bottom: 15px;
}
.productImageSlider {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  max-width: 100vw;
}
.sliderProductsInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60%;
  gap: 30px;
  text-align: center;
}
.sliderProductsImages {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  gap: 40px;
  max-width: 100%;
  /* margin: 50px; */
}
.sliderProductsImage {
  width: 150px;
  height: 50px;
  object-fit: contain;
  filter: grayscale(100%);
  transition: filter 0.5s;
}

.sliderProductsImage:hover {
  filter: grayscale(0%);
}
.currentIndicatorProducts {
  background-color: #00000072;
  border-radius: 30px;
  border: 1px solid #b3b3b3;
  height: 30px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.7rem;
  cursor: pointer;
  margin: 50px;
}

.volumeSliderContainer {
  background-color: #00000072;
  border-radius: 30px;
  border: 1px solid #b3b3b3;
  height: 30px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.7rem;
  position: absolute;
  left: 2rem;
  bottom: 15px;
}

.volumeSlider {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  width: 100px;
  height: 30px;
  position: relative;
  border: none;
  outline: none; /* Prevent focus outline */
}

/* .volumeSlider:hover,
.volumeSlider:active,
.volumeSlider:focus {
  background: none;
  outline: none;
} */

.volumeSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  position: relative;
  top: -6px; /* This moves the thumb up */
}

.volumeSlider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  position: relative;
  top: -6px; /* This moves the thumb up */
}

.volumeSlider::-webkit-slider-runnable-track {
  height: 3px;
  background: #ffffff;
  border: none;
  border-radius: 3px;
}

.volumeSlider::-moz-range-track {
  height: 3px;
  background: #ffffff;
  border: none;
  border-radius: 3px;
}

.volumeSlider:hover {
  background: rgba(255, 255, 255, 0.3);
}

@media screen and (max-width: 800px) {
  .volumeSliderContainer {
    width: fit-content;
    left: auto;
    right: 2rem;
    bottom: 55px;
  }

  .sliderProductsImage {
    filter: grayscale(0%);
  }
  .arrowLeft {
    background-color: #00000072;
    left: 5px;
  }
  .arrowRight {
    background-color: #00000072;
    right: 5px;
  }
}
