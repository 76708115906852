.cronologia {
  margin-top: 18vh;
}

.cronologiaBg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: 0 auto;
  min-height: 60vh;
}
.orderCard {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 10px;
  padding: 0 20px 20px 20px;
  box-sizing: border-box;
  gap: 20px;
  position: relative;
  min-width: 60vw;
  max-width: 800px;
}
