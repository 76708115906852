.loginBg {
  margin-top: 12vh;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loginBg h1 {
  margin: 50px;
}
.inputButton {
  background-color: white;
  width: 300px;
  height: max-content;
  border: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 80px;
  position: relative;
  margin: 10px;
  padding: 10px 20px;
}
.inputButton input {
  width: 80%;
  text-decoration: none;
  border-radius: 5px;
  box-sizing: border-box;
  border: none;
  padding: 10px;
}
.inputButton img {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.mainButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: #005192;
  color: #fff;
  width: 300px;
  height: 20px;
  border: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 80px;
  padding: 20px;
  margin: 10px;
  cursor: pointer;
}
.errorText {
  color: red;
  font-size: small;
}
