.navbar {
  width: 100%;
  min-height: 12vh;
  max-height: max-content;
  top: 0;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 0.5rem 1rem;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  z-index: 999;
}
.logo {
  min-width: 100px;
}
.navbarLogoGhost {
  min-width: 100px;
  position: relative;
  overflow: visible;
}
.navbarLogoGhost img {
  cursor: pointer;
}
.accountMenu {
  position: absolute;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  gap: 0.5rem;
  padding: 0.5rem;
  box-sizing: border-box;
  border: 1px solid #cdcdcd;
  width: max-content;
  z-index: 1000;
}
.logoutButton {
  color: red;
}
.accountMenuOption {
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 35px;
}
.accountMenuOption:hover {
  background-color: #e8e8e8;
}
.navbarTitle {
  color: #005192;
  margin: 10px;
}
.navbarOptions {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 2rem;
  font-size: large;
  font-weight: 400;
}
.navbarOption {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  font-size: 18px;
}
.navbarOption::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #005192;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.navbarOption:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.navbarDivider {
  background-color: #cdcdcd;
  width: 1px;
  height: 1.5rem;
}

.navbarCenter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
}
.navbarCenterMobile {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
}

.navbarLogoGhost {
  width: 90px;
  height: 60px;
}
.hamburgerIcon {
  cursor: pointer;
}
