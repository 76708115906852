.dashboard {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  margin-top: 20vh;
}
.dashboardBg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: 0 auto 150px auto;
  text-align: center;
}

.downloadButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  background-color: white;
  border-radius: 80px;
  padding: 10px 40px;
  color: #005192;
  font-weight: 500;
  cursor: pointer;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.selectDiv {
  width: 100%;
}
.inputBottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 100%;
}
.resultTab {
  margin: 80px 0;
}
.resultProduct {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 3fr 3fr;
  margin-top: 20px;
  width: 100%;
  gap: 20px;
  padding: 20px 0 50px 20px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.resultProductHeader {
  font-size: medium;
  font-weight: 500;
  color: #005192;
}
.resultProductDescription {
  overflow: hidden;
  white-space: wrap;
  flex-grow: 1;
  max-width: 300px;
}
.productBottomRight {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  border-radius: 5px;
  height: fit-content;
}
.miniHeader {
  font-weight: 500;
  color: #005192;
}
.addProduct {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  padding: 5px;
  max-width: 20px;
  max-height: 20px;
  background-color: #005192;
}
.dashboardButton {
  position: fixed;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  bottom: 20px;
  padding: 10px 30px;
  border-radius: 50px;
  background-color: #005192;
  color: white;
  font-size: medium;
  font-weight: 600;
  cursor: pointer;
}

.smButton {
  background-color: white;
  border: none;
  color: black;
  padding: 10px 20px;
  border-radius: 30px;
  max-width: fit-content;
  cursor: pointer;
  margin: auto;
  height: 14px;
  margin: 25px auto 0 auto;
  padding: 20px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
