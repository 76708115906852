.aziendaAssistenza {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 12vh;
}
.aziendaAssistenzaHeader {
  margin-top: 50px;
}
.aziendeAssistenzaCards {
  display: flex;
  width: 100%;

  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.aziendaAssistenzaCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 400px;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  margin: 50px;
  width: 40%;
  min-height: max-content;
  padding: 30px;
  box-sizing: border-box;

  border-radius: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.aziendaAssistenzaImages {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.aziendaAssistenzaImage {
  width: 120px;
  height: 60px;
  object-fit: contain;
  border: none;
  background-color: none;
}
.assistenzaNumber {
  color: #1a9af0;
  text-decoration: none;
  font-size: larger;
  height: 10%;
}
.zoneAssistenza {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin: 50px;
}

.zoneAssistenzaCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: white;
  border-radius: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 40%;
  padding: 0 20px;
  box-sizing: border-box;
}
@media screen and (max-width: 800px) {
  .aziendaAssistenzaCard {
    min-width: 90%;
    margin-bottom: 20px;
  }
  .zoneAssistenzaCard {
    width: 90%;
  }
}
